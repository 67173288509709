import CFetch from "./fetch";
import { ICarerBankDetails, IReview } from "@urbancarecommunity/commons/@types";
import { ICarerDetails } from "@urbancarecommunity/commons/@types";
import { ICarerDocument } from "@urbancarecommunity/commons/@types";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const searchCarers = (paginateOptions: any) => {
  return CFetch.callAPI(endpoints.searchInfo.build(), {
    params: paginateOptions,
  });
};

export const exportCarers = () => {
  return CFetch.callAPI(endpoints.exportCarers.build());
};

export const searchCarersForBooking = (searchParams: any) => {
  return CFetch.callAPI(endpoints.search.build(), {
    method: "POST",
    body: searchParams,
  });
};

export const getCarerProfile = (userId: string) => {
  return CFetch.callAPI(endpoints.profile.buildWithParams({ id: userId }));
};

export const getCarerProfileOpenCareWorker = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileOpenCareWorker.buildWithParams({ id: userId })
  );
};

export const getBankDetails = (userId: string) => {
  return CFetch.callAPI<ICarerBankDetails>(
    endpoints.bankDetails.buildWithParams({ id: userId })
  );
};

export const removeBankDetails = (userId: string) => {
  return CFetch.callAPI<ICarerBankDetails>(
    endpoints.bankDetails.buildWithParams({ id: userId }),
    { method: "DELETE" }
  );
};

export const updateBankDetails = (
  userId: string,
  details: Pick<ICarerBankDetails, "accountType">
) => {
  return CFetch.callAPI<ICarerBankDetails>(
    endpoints.bankDetails.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: details,
    }
  );
};

export const getApplicationStatus = (userId: string) => {
  return CFetch.callAPI(
    endpoints.applicationStatus.buildWithParams({ id: userId })
  );
};

export const getProfileDetails = (userId: string) => {
  return CFetch.callAPI(endpoints.profile.buildWithParams({ id: userId }));
};

export const getOnboardStatus = (userId: string) => {
  return CFetch.callAPI(
    endpoints.onboardStatus.buildWithParams({ id: userId })
  );
};

export const updateOnboardStatusFalse = (userId: string) => {
  return CFetch.callAPI(
    endpoints.onboardStatus.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: { showOnBoardInfo: false },
    }
  );
};

export const getBasicInfo = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileBasicInfo.buildWithParams({ id: userId })
  );
};
export const updateBasicInfo = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profileBasicInfo.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const getPersonalDetails = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profilePersonalDetails.buildWithParams({ id: userId })
  );
};
export const updatePersonalDetails = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profilePersonalDetails.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const getJobsAppliedFor = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileJobsAppliedFor.buildWithParams({ id: userId })
  );
};

export const updateJobsAppliedFor = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profileJobsAppliedFor.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const getExperiencesAndTrainings = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileExpAndTra.buildWithParams({ id: userId })
  );
};
export const updateExperiencesAndTrainings = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profileExpAndTra.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const getQualifications = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileQualifications.buildWithParams({ id: userId })
  );
};
export const updateQualifications = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profileQualifications.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const getDocumentation = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileDocuments.buildWithParams({ id: userId })
  );
};
export const updateDocumentation = (
  userId: string,
  update: Partial<ICarerDetails>
) => {
  return CFetch.callAPI(
    endpoints.profileDocuments.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: update,
    }
  );
};

export const uploadDocument = (
  userId: string,
  file: File,
  details: Partial<ICarerDocument>
) => {
  const data = new FormData();
  data.append("document", file);
  data.append("category", details.category || "Unknown");

  return CFetch.callAPI(endpoints.document.buildWithParams({ id: userId }), {
    method: "PUT",
    body: data,
    fileUpload: true,
  });
};
export const getAllDocuments = (userId: string) => {
  return CFetch.callAPI(endpoints.document.buildWithParams({ id: userId }));
};
export const getDocument = (userId: string, docId: string) => {
  return CFetch.callAPI(
    endpoints.documentGet.buildWithParams({ id: userId, docId })
  );
};
export const deleteDocument = (userId: string, docId: string) => {
  return CFetch.callAPI(
    endpoints.documentDelete.buildWithParams({ id: userId, docId }),
    { method: "DELETE" }
  );
};
export const updateUploadFiles = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileUploadFiles.buildWithParams({ id: userId }),
    {
      method: "PUT",
    }
  );
};

export const submitApplication = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileSubmit.buildWithParams({ id: userId }),
    {
      method: "PUT",
    }
  );
};

export const approveProfile = (userId: string, approved: boolean) => {
  return CFetch.callAPI(
    endpoints.profileApprove.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: { approved },
    }
  );
};

export const toggleDisableStatus = (userId: string, disabled: boolean) => {
  return CFetch.callAPI(
    endpoints.profileDisable.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: { disabled },
    }
  );
};

export const deleteCarer = (userId: string) => {
  return CFetch.callAPIWithRichAuth(
    endpoints.profile.buildWithParams({ id: userId }),
    {
      method: "DELETE",
      hash: userId,
    }
  );
};

export const uploadAvatar = (userId: string, file: File) => {
  const data = new FormData();
  data.append("avatar", file);

  return CFetch.callAPI(
    endpoints.profileAvatar.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: data,
      fileUpload: true,
    }
  );
};

export const addAvailability = (userId: string, details: any) => {
  return CFetch.callAPI(
    endpoints.availabilities.buildWithParams({ id: userId }),
    {
      method: "POST",
      body: details,
    }
  );
};

export const getAvailabilities = (userId: string, startDate: string) => {
  return CFetch.callAPI(
    endpoints.availabilities.buildWithParams({ id: userId }),
    {
      params: {
        startDate,
      },
    }
  );
};

export const getJobPosts = (option: any) => {
  return CFetch.callAPI(endpoints.getJobPosts.buildWithParams({}), {
    method: "POST",
    body: option,
  });
};

export const getJobPostById = (id: string) => {
  return CFetch.callAPI(endpoints.getJobPostById.buildWithParams({ id }));
};

export const getJobPostShiftById = (id: string) => {
  return CFetch.callAPI(endpoints.getJobPostShiftById.buildWithParams({ id }));
};

export const applyJobPost = (jobPostId: string, role: []) => {
  return CFetch.callAPI(endpoints.jobPostApply.buildWithParams({}), {
    method: "POST",
    body: { jobPostId, role },
  });
};

export const applyJobPostShift = (shiftId: string, role: []) => {
  return CFetch.callAPI(endpoints.jobPostApply.buildWithParams({}), {
    method: "POST",
    body: { shiftId, role },
  });
};

export const cancelJobPostShift = (shiftId: string) => {
  return CFetch.callAPI(endpoints.jobPostCancel.buildWithParams({}), {
    method: "PUT",
    body: { shiftId },
  });
};

export const reviewTasks = (
  id: string,
  shiftId: string,
  tasksIds: [string]
) => {
  return CFetch.callAPI(
    endpoints.shiftTaskReview.buildWithParams({
      id,
    }),
    {
      method: "PUT",
      body: {
        shiftId,
        tasksIds,
      },
    }
  );
};

export const reviewServiceUser = (
  id: string,
  jobId: string,
  reviewDetail: {
    serviceUserId: string;
    clientId: string;
    review: IReview;
  }
) => {
  return CFetch.callAPI(
    endpoints.jobReviewServiceUser.buildWithParams({
      id,
      jobId,
    }),
    {
      method: "PUT",
      body: reviewDetail,
    }
  );
};

export * from "./carer/jobRoles";
export * from "./carer/bankDetails";
export * from "./carer/availability";
export * from "./carer/booking";
export * from "./carer/documentChecklist";
